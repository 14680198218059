import { template as template_ea02c99d58df4445a8b244a4bbb80c93 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ea02c99d58df4445a8b244a4bbb80c93(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
