import { template as template_ff02313f5f404e69abdd3768b9737792 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ff02313f5f404e69abdd3768b9737792(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
