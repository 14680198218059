import { template as template_37ad5a55bb9d45b4aa7a4bf82cbed61f } from "@ember/template-compiler";
const FKText = template_37ad5a55bb9d45b4aa7a4bf82cbed61f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
