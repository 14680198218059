import { template as template_67e81b627a704ea7a60d0f3ebfec94d8 } from "@ember/template-compiler";
const FKLabel = template_67e81b627a704ea7a60d0f3ebfec94d8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
